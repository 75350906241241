<template>
  <div>
    <p class="text-h6 font-weight-black grey--text">Faturas</p>

    <v-data-table
      :headers="headers"
      :items="invoices"
      hide-default-footer
      :items-per-page.sync="itemsPerPage"
      :disable-sort="$vuetify.breakpoint.smAndDown"
      :sort-by="'createdAt'"
      :sort-desc="true"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | moment('DD/MM/Y HH:mm') }}
      </template>
      <template v-slot:item.dueDate="{ item }">
        {{ item.dueDate | moment('DD/MM/Y') }}
      </template>
      <template v-slot:item.paidAt="{ item }">
        {{ item.paidAt | moment('DD/MM/Y') }}
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.total | currency }}
      </template>
      <template v-slot:item.paymentGatewayUrl="{ item }">
        <v-btn
          icon
          :disabled="!item.paymentGatewayUrl"
          :href="item.paymentGatewayUrl"
          title="Baixar Fatura"
          target="_blank"
        >
          <v-icon color="orange darken-2" size="21">cloud_download</v-icon>
        </v-btn>
      </template>
      <template slot="no-data">
        <p class="text-center blue--text py-6 text-h5">
          <v-icon color="blue" medium>info</v-icon> Não há extrato.
        </p>
      </template>
    </v-data-table>
    <v-col class="text-end" v-if="hasMoreInvoices">
      <v-btn color="info" small outlined @click="toggleAll()">
        <span v-if="isShowAll">esconder<v-icon>expand_less</v-icon></span>
        <span v-else> ver todas as faturas <v-icon>expand_more</v-icon></span>
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'statement-previous',
  data() {
    return {
      itemsPerPage: 5,
      options: {
        sortBy: 'classification',
        descending: true,
        itemsPerPage: 5,
      },
      headers: [
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Criada em',
          value: 'createdAt',
        },
        {
          text: 'Vencimento',
          value: 'dueDate',
        },
        {
          text: 'Paga em',
          value: 'paidAt',
        },
        {
          text: 'Valor',
          value: 'total',
          align: 'left',
        },
        {
          text: 'link',
          value: 'paymentGatewayUrl',
          width: '10px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      invoices: 'invoices/invoices',
    }),
    hasMoreInvoices() {
      return this.invoices.length >= 5
    },
    isShowAll() {
      return this.itemsPerPage === -1
    },
  },
  methods: {
    toggleAll() {
      if (this.isShowAll) {
        this.itemsPerPage = 5
      } else {
        this.itemsPerPage = -1
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
