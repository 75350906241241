<template>
  <v-row>
    <v-subheader>Minha Conta > Extrato de Utilização</v-subheader>
    <v-card width="100%">
      <base-header-information
        :title="headerTitle"
        :description="headerDescription"
      >
      </base-header-information>
      <v-row class="mt-6 pa-12" v-if="!loading">
        <v-col class="elevation-1 mb-10" cols="12">
          <s-current />
        </v-col>
        <v-col class="elevation-1" cols="12">
          <s-previous />
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
import SCurrent from './current.vue'
import SPrevious from './previous.vue'

export default {
  name: 'UsageStatement',
  components: {
    SCurrent,
    SPrevious,
  },
  computed: {},
  data() {
    return {
      loading: true,
      headerTitle: 'Extrato de Utilização',
      headerDescription: `Acompanhe seus extratos de utilização mensal do Simplificador e
    se preferir, baixe sua fatura por aqui para realizar o pagamento ao final de cada mês.`,
    }
  },
  async mounted() {
    this.loading = true
    await Promise.all([
      this.$store.dispatch('invoices/getInvoices'),
      this.$store.dispatch('paymentInfo/getPaymentData'),
    ])
    this.loading = false
  },
}
</script>
<style scoped></style>
