import { render, staticRenderFns } from "./UsageStatement.vue?vue&type=template&id=49472a3e&scoped=true"
import script from "./UsageStatement.vue?vue&type=script&lang=js"
export * from "./UsageStatement.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49472a3e",
  null
  
)

export default component.exports