<template>
  <div>
    <p class="ma-0 text-h6 font-weight-black grey--text">Próxima fatura</p>

    <v-data-table
      :headers="headers"
      :items="items"
      hide-default-footer
      :items-per-page.sync="itemsPerPage"
      :disable-sort="$vuetify.breakpoint.smAndDown"
    >
      <template v-slot:item.expireAt="{ item }">
        {{ item.expireAt | moment('DD/MM/Y') }}
      </template>
      <template v-slot:item.classification="{ item }">
        {{ item.classification | translate }}
      </template>
      <template v-slot:item.price="{ item }">
        {{ item.price | currencyFromCents }}
      </template>

      <template v-slot:body.append>
        <tr>
          <td></td>
          <td></td>
          <td class="text-end">Total:</td>
          <td>{{ total | currencyFromCents }} </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'statement-current',
  data() {
    return {
      itemsPerPage: -1,
      headers: [
        {
          text: 'Descrição',
          value: 'name',
        },
        {
          text: 'classification',
          value: 'classification',
        },
        {
          text: 'Quantidade',
          value: 'quantity',
        },
        {
          text: 'Preço',
          value: 'price',
          align: 'start',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      signature: 'paymentInfo/companyPaymentInfo',
    }),
    items() {
      return [
        this.signature.currentSubscription.planItem,
        ...this.signature.itemsForNextCharges,
      ]
    },
    total() {
      return this.items.reduce((total, item) => {
        return total + item.price * item.quantity
      }, 0)
    },
  },
}
</script>

<style lang="scss" scoped></style>
